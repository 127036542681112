import { createContext, useContext } from 'react'
import { ExtendedContextModel } from '../../lib/mapContext'
import OlMap from 'ol/Map'

export type MapContext_ = {
  context?: ExtendedContextModel;
  map?: OlMap;
  ready: boolean;
  setMap (map?: OlMap): void;
}

/**
 * Contexte de la carte.
 */
export const MapContext = createContext<MapContext_>(null)

/**
 * Retourne le contexte de la carte.
 */
function useMapContext () {
  return useContext(MapContext)
}

export default useMapContext
